<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="lists"
    :options.sync="options"
    :items-per-page.sync="tableMeta.per_page"
    :server-items-length="tableMeta.total"
    :footer-props="footer"
    class="elevation-1 row-pointer"
    @dblclick:row="rowClick"
  >
    <template v-slot:[`item.approval`]="{ item }">
      <v-icon color="green" v-if="item.approval == 1">mdi-check</v-icon>
      <v-icon color="red" v-else-if="item.approval == 2">mdi-cancel</v-icon>
      <v-icon color="blue" v-else-if="item.approval == 0">mdi-timelapse</v-icon>
      <v-icon color="grey" v-else>mdi-circle</v-icon>
    </template>
    <template v-slot:[`item.plan_date`]="{ item }">
      {{
        moment(item.plan_date)
          .add(543, 'year')
          .format('D MMMYYYY')
      }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-speed-dial open-on-hover direction="left">
        <v-btn color="blue darken-4" icon slot="activator" small dark fab>
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              v-if="item.can.update"
              color="primary"
              v-on="on"
              v-bind="attrs"
              @click="edit(item.id)"
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
          <span>แก้ไข</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark x-small color="primary" v-on="on" v-bind="attrs" @click="$emit('show-pdf', item.id)">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
          <span>พิมพ์คำสั่ง</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="item.can.update"
              fab
              dark
              x-small
              color="green"
              v-on="on"
              v-bind="attrs"
              @click="$emit('duplicate', item.id)"
            >
              <v-icon>mdi-content-duplicate</v-icon>
            </v-btn>
          </template>
          <span>คัดลอกคำสั่ง</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              fab
              v-if="item.can.delete"
              @click.stop="removeData(item.id)"
              color="red"
              dark
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>ลบคำสั่ง</span>
        </v-tooltip>
      </v-speed-dial>
    </template>
  </v-data-table>
</template>

<script>
import { list } from '@/api/command'
import { mapGetters } from 'vuex'
export default {
  methods: {
    getList() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      const param = {
        page: page,
        limit: itemsPerPage,
        keyword: this.keyword,
        type: this.type,
        start: this.dateStart,
        end: this.dateEnd,
        bureau: this.bureauId,
        division: this.divisionId,
        station: this.stationId,
      }
      list(param)
        .then(res => {
          this.lists = res.data
          let meta = res.meta
          let itemsPerPage = parseInt(meta.per_page)
          this.tableMeta = {
            itemsPerPage: itemsPerPage,
            per_page: itemsPerPage,
            page: meta.current_page,
            total: meta.total,
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowClick(e, data) {
      // console.log(data)
      this.$router.push({ path: `command/${data.item.id}` })
    },
    edit(id) {
      this.$router.push({ path: `command/${id}` })
    },
    removeData(id) {
      this.$emit('remove-command', id)
    },
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  watch: {
    options: {
      handler() {
        this.getList()
      },
      deep: true,
    },
    bureauId(v) {
      this.getList()
    },
    divisionId(v) {
      this.getList()
    },
    stationId(v) {
      this.getList()
    },
    datesSelected(v) {
      this.getList()
    },
  },
  created() {
    this.getList()
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: 'สถานะ', value: 'approval', sortable: false },
        { text: 'สถานี', value: 'station', sortable: false },
        {
          text: 'คำสั่งเลขที่',
          sortable: false,
          value: 'plan_number',
        },
        {
          text: 'ลงวันที่',
          sortable: false,
          value: 'plan_date',
        },
        {
          text: 'ใช้ช่วงวันที่',
          sortable: false,
          value: 'dateranges',
        },
        {
          text: 'ผู้ลงนาม',
          sortable: false,
          value: 'owner',
        },
        {
          text: 'บก./ภ.จว.',
          value: 'division',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      footer: {
        itemsPerPageOptions: [10, 50, 100, 200],
        showCurrentPage: true,
        showFirstLastPage: true,
      },
      tableMeta: {
        itemsPerPage: 10,
        per_page: 10,
        page: 1,
        total: 0,
      },
      lists: [],
      options: {},
    }
  },
  computed: {
    ...mapGetters('Appfilter', [
      'bureaus',
      'bureauId',
      'divisionId',
      'stationId',
      'divisions',
      'stations',
      'dateStart',
      'dateEnd',
      'datesSelected',
    ]),
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
