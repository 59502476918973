<template>
  <v-container fluid>
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear color="red" height="5" indeterminate></v-progress-linear>
      </template>
      <v-toolbar color="indigo darken-3" dark dense flat>
        <v-toolbar-title>
          <v-icon>
            mdi-text-box
          </v-icon>
          รายการคำสั่ง
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          :items="types"
          label="ค้นหาจาก"
          style="width:40px; !important"
          v-model="type"
          hide-details
          single-line
          dense
          solo-inverted
        ></v-select>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          width="200px"
          single-line
          hide-details
          dense
          solo-inverted
          label="กรอกคำค้นหา"
          clearable
          v-model="keyword"
          @keypress.enter="getList"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-btn color="indigo darken-2" dark to="command/new">
          <v-icon>
            mdi-plus-box
          </v-icon>
          เพิ่มคำสั่งใหม่
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-text>
      <command-table
        :keyword="keyword"
        :type="type"
        ref="ctable"
        @show-pdf="showPdf"
        @duplicate="copyCommand"
        @remove-command="deleteCommand"
      ></command-table>
    </v-card>

    <v-sheet>
      <v-icon color="green">mdi-check</v-icon>อนุมัติ <v-icon color="red">mdi-cancel</v-icon>ไม่อนุมัติ
      <v-icon color="grey">mdi-circle</v-icon>ยังไม่ขออนุมัติ<v-icon color="blue">mdi-timelapse</v-icon>รอการอนุมัติ
    </v-sheet>

    <!-- Snack bar -->
    <v-snackbar :timeout="2000" v-model="showAlert" absolute centered :color="snackColor">
      {{ message }}
    </v-snackbar>

    <!-- dialog -->
    <bjp-confirm-dialog ref="confirm" />

    <!-- pdf dialog -->
    <pdf-dialog ref="pdf"></pdf-dialog>
  </v-container>
</template>

<script>
import commandTable from './components/commandTable'
import { duplicate, destroy, getPdf } from '@/api/command'
import pdfDialog from '@/components/pdfDialog'
export default {
  components: {
    commandTable,
    pdfDialog,
  },
  data() {
    return {
      keyword: '',
      type: 'plan_number',
      types: [
        { text: 'เลขคำสั่ง', value: 'plan_number' },
        { text: 'หัวเรื่อง', value: 'name' },
      ],
      // snackbar
      showAlert: false,
      snackColor: 'primary',
      message: '',
      loading: false,
    }
  },
  methods: {
    getList() {
      this.$refs.ctable.getList()
    },
    copyCommand(id) {
      this.loading = true
      duplicate(id)
        .then(res => {
          if (res.success) {
            this.showMessage('ทำรายการเรียบร้อยแล้ว')
            this.getList()
          }
        })
        .catch(err => {
          this.showMessage('ไม่สามารถทำรายการได้', 'red')
        })
        .finally(() => {
          this.loading = false
        })
    },
    async deleteCommand(id) {
      let dlg = await this.$refs.confirm.open('ลบรายการ', 'คุณต้องการลบรายการนี้ ?')
      if (dlg) {
        this.loading = true
        destroy(id)
          .then(res => {
            this.showMessage('ทำรายการเรียบร้อยแล้ว')
            this.$refs.ctable.getList()
          })
          .catch(err => {
            this.showMessage('ไม่สามารถทำรายการได้', 'red')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async showPdf(id) {
      this.loading = true
      try {
        const pdfBase64 = await getPdf(id)
        this.$refs.pdf.open(pdfBase64.pdf)
      } catch (error) {
        this.$toast.error({
          message: error.response.data.message,
          title: 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    showMessage(msg, color) {
      if (color == undefined) color = 'primary'
      this.snackColor = color
      this.message = msg
      this.showAlert = true
    },
  },
  created() {},
}
</script>

<style></style>
